import { Image } from '@ks89/angular-modal-gallery';
import { CategoryListComponent } from '../components/constant/category/category-list/category-list.component';
import { BaseModel } from './base';

export class category extends BaseModel {
  name_en: string = "";
  name_ar: string = "";
  text_en: string = "";
  text_ar: string = "";
  image: string = "";
  cover_image: string = "";
  created_at: Date = new Date();
  is_active: Boolean = true;
  value_2: string = "";
}

export class continentSliders extends BaseModel {
  title_ar: string = "";
  title_en: string = "";
  text_ar: string = "";
  text_en: string = "";
  image: string = "";
  background: string = "";
  created_at: Date = new Date();
  is_active: Boolean = true;
  continent_id: string = "";
  continent: category
}


export class sub_category extends BaseModel {
  name_en: string = "";
  name_ar: string = "";
  created_at: Date = new Date();
  is_active: Boolean = true
  parent_id = "";
  parent: category
}

export class feature extends BaseModel {
  titleAR: string = ""
  titleEN: string = ""
}

export class sub_feature extends BaseModel {
  titleAR: string = ""
  titleEN: string = ""
  attributeId: number = 0;
  value: string = "";
  status: boolean = true;
}

export class product extends BaseModel {
  title_en: string = ""
  title_ar: string = ""
  title: string = ""
  description_en: string = ""
  description_ar: string = ""
  description: string = ""
  sku: string = ""
  rate: number = 0
  image: string = ""
  price: number = 0
  price_wt: number = 0
  discount_percentage: number = 0
  discount_wt: number = 0
  weight: number = 0
  length: number = 0
  width: number = 0
  height: number = 0
  available_quantities: number = 0
  lowStockThresold: number = 0
  isBackOrder: number = 0
  tax: number = 0
  images: any[] = []
  imageName: string = ""
  tags: string[] = []
  tagsString: string = ""
  imagesRect: Image[] = []
  includeTax: boolean = false
  vendorId: number = 0
  type: string = ""
  pageTitle: string = ""
  seoURL: string = ""
  pageDescription: string = ""
  noteAR: string = ""
  noteEN: string = ""
  notes: string = ""
  status: string = ""
  category_id: string = ""
  sub_category_id: string = ""
  vendor_id: string = ""
  continent_id: string = ""
  quantities: number = 0
  category: category
  sub_category: sub_category
  vendor: brand
  discount_start_at: string = ""
  discount_end_at: string = ""
  is_discount: Boolean = false
  properties: any[] = []
  minimum_quantity_alert: number = 0
}

export class brand extends BaseModel {
  user_name: string = "";
  name_en: string = "";
  name_ar: string = "";
  bio_en: string = "";
  bio_ar: string = "";
  email: string = "";
  address_en: string = "";
  address_ar: string = "";
  image: string = "";
  cover_image: string = "";
  phone: string = "";
  tax: string = "";
  user_id: string = "";
  branch_id: string = "";
  createdAt: string = ""
  latitude: number = 0;
  longitude: number = 0;
  is_active: number = 0;
  rate: number = 0;
  address: string = "";
  address2: string = "";
  name: string = "";
  created_at: string = "";
  username: string = "";
  count_reviews: number = 0
  city: string = ""
  governorate: string = ""
  attachments: []
  country: string = ""
  verifications_request: {}
  country_culture_id: number = 0
  shipping_fee_status='automatic'
  shipping_fee:string=""
  system_commission_rate:string=""
}

export class user extends BaseModel {
  firstName: string = ""
  lastName: string = ""
  email: string = ""
  phone: string = ""
  countryCode: string = ""
  userName: string = ""
  image: string = ""
  createdAt: string = ""
}

export class Order extends BaseModel {
  id: number = 0
  serial: string = ""
  user_id: number = 0
  sub_total: number = 0
  discount: number = 0
  shipping_fee: number = 0
  e_ststus: string = ""
  total: number = 0
  coupon: any
  user: any
  payment_method: string = ""
  status: string = ""
  is_ship_order: number = 0
  created_at: string = ""
  notes: string = ""
  orders: OrderDetails[]
  addresses: Address[]
  e_status: string = ""
}

export class OrderDetails extends BaseModel {
  id: number = 0
  serial: string = ""
  vendor_id: number = 0
  vendor: string = ""
  sub_total: number = 0
  e_ststus: string = ""
  discount: number = 0
  shipping_fee: number = 0
  total: number = 0
  notes: string = ""
  status: string = ""
  items: OrderProducts[]
}

export class OrderProducts extends BaseModel {
  id: number = 0
  order_id: number = 0
  product_id: number = 0
  product_title: string = ""
  product_image: string = ""
  quantity: number = 0
  product_price: number = 0
  vendor_id: number = 0
  specifications: any
  created_at: string = ""
}

export class Address extends BaseModel {
  id: number = 0
  first_name: string = ""
  last_name: string = ""
  address_1: string = ""
  address_2: string = ""
  country_id: number = 0
  governorate_id: number = 0
  city_id: number = 0
  zip_code: number = 0
  type: string = ""
  created_at: string = ""
  governorate: any
  country: any
  city: any
}

export class staic extends BaseModel {
  titleAR: string = ""
  titleEN: string = ""
  title: string = ""
}

